import React from 'react'
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const Submenu = () => {
    const selector = useSelector((state) => state.Navbar);
    const navSubmenu = selector.submenu
    const {isSubmenuOpen, location} = selector;
    const container = useRef(null);

    useEffect(()=>{
        const submenu = container.current;
        const { center, bottom } = location;
        submenu.style.left = `${center}px`;
        submenu.style.top = `${bottom}px`;
    },[location]);
    return (
        <div className={isSubmenuOpen ? "submenu submenu-show": "submenu"} ref={container}>
            {navSubmenu.map((submenu, index)=>{
                const {label, icon, link} = submenu;
                return (
                  <div
                    className="d-flex align-items-center submenu-item justify-content-start py-2"
                    key={index}
                  >
                    <div className="submenu-icon">{icon}</div>
                    <div className=" px-2">
                      <Link to={link}>{label}</Link>
                    </div>
                  </div>
                );
            })}
        </div>
    )
}

export default Submenu
