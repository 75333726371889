import React from 'react';
import { SiFacebook, SiInstagram, SiTwitter } from "react-icons/si";

const SocialMediaIcon = () => {
    return (
      <div className="font-open footer-icon-holder py-3">
        <div className="d-flex justify-content-center align-items-center text-center">
          <div
            className="footer-icon mx-3"
            onClick={() => {
              window.location.href = "https://www.facebook.com/emas.anissir/";
            }}
          >
            <span className="footer-social-icon mx-2">
              <SiFacebook />
            </span>
            FACEBOOK
          </div>
          <div className="footer-icon mx-3">
            <span className="footer-social-icon mx-2">
              <SiInstagram />
            </span>
            INSTAGRAM
          </div>
          <div className="footer-icon mx-3">
            <span className="footer-social-icon mx-2">
              <SiTwitter />
            </span>
            TWITTER
          </div>
        </div>
      </div>
    );
}

export default SocialMediaIcon
