import React from 'react'

const CourseSub = ({subject}) => {
    return (
        <div className='d-flex flex-column align-items-start justify-content-start mx-3 my-3'>
            <p className="text-content font-cormogrant">{subject}</p>
        </div>
    )
}

export default CourseSub
