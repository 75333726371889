import React from 'react'
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import {Link} from 'react-router-dom';



const CourseCard = ({title, s1, s2, s3, anim}) => {
    return (
      <div className="col-md-4 font-cormogrant anim-left">
        <div className={`card ${anim}`}>
          <div className="card-header">
            <h3 className="card-title font-bold pt-3">{title}</h3>
          </div>
          <div className="card-body">
            <div className="d-flex flex-column justify-content-center align-items-start">
              <h4>{s1}</h4>
              <h4>{s2}</h4>
              <h4>{s3}</h4>
            </div>
            <div className="py-3">
              <Link to="/course" className="course-card-btn">
                More info
                <span className="pl-4">
                  <FaRegArrowAltCircleRight />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
}

export default CourseCard
