import React from "react";
import CourseCard from "./CourseCard";

const CourseSection = () => {
  return (
    <section className="course-section">
      <h2 className="text-title font-cormogrant text-uppercase text-center py-5">
        Our Courses
      </h2>
      <div className="container pb-4">
        <div className="row gy-4">
          <CourseCard
            key={1}
            title="IGCSE (O'L)"
            s1="Mathematics A"
            s2="Mathematics B"
            s3="Further Pure Mathematics"
            anim="left-transition"
          />
          <CourseCard
            key={2}
            title="IAL (A'L)"
            s1="Pure Mathematics (P1)"
            s2="Pure Mathematics (P2)"
            s3="Pure Mathematics (P3)"
            anim="opacity-anim"
          />
          <CourseCard
            key={3}
            title="CAMBRIDGE"
            s1="Mathematics D"
            s2="Additional Mathematics"
            s3="Others"
            anim="right-transition"
          />
        </div>
      </div>
    </section>
  );
};

export default CourseSection;
