import React from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const NoticeContent = () => {
  const noticeItem = useSelector((state) => state.Notice.notices);
 if(noticeItem.length < 1){
   return (<div className='font-cormogrant'>
     <h2 className='text-title-s notice-error'>Notice box is empty</h2>
   </div>)
 }else{
     return (
       <div className="all-notice">
         {noticeItem.map((item) => {
           const { id, title } = item;
           return (
             <Link to={`/notice/${id}`} key={id} className="single-notice">
               {title}
             </Link>
           );
         })}
       </div>
     );
 }


};

export default NoticeContent;
