import React from "react";
import { useSelector } from "react-redux";
import List from "./List";
import Title from "../../../ReuseableComp/Title";

const BookListContainer = () => {
  const selector = useSelector((state) => state.BookList.data);

  return (
    <div>
      <div className="my-5">
        <Title title="recommended book list" />
      </div>
      <div className="container">
        <div className="row justify-content-md-center">
          <h2 className="title font-cormogrant text-uppercase text-bold">
            IGCSE (O'L) Books :
          </h2>
          {selector.map((book) => {
            const { id, url, subject, grade } = book;
            if (grade === "IGCSE (O'L)") {
              return <List id={id} url={url} subject={subject} grade={grade} />;
            }
          })}
        </div>
        <div className="row justify-content-md-center">
          <h2 className="title font-cormogrant text-uppercase text-bold">
            IAL (A'L) Books :
          </h2>
          {selector.map((book) => {
            console.log(book);
            const { id, url, subject, grade } = book;
            if (grade === "IAL (A'L)") {
              return <List id={id} url={url} subject={subject} grade={grade} />;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default BookListContainer;
