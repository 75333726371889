import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { fitlerNotice, setCategory } from "../../../Redux/Rducers/NoticeSlice";
import NoticeContent from "./NoticeContent";
import { useEffect } from "react";
import Title from './../../ReuseableComp/Title';

const NoticeBox = () => {
     const dispatch = useDispatch();
     const selector = useSelector((state) => state.Notice);
     const noticeNav = selector.noticeNav;
     const noticeItem = selector.data;
     let allCategory = [
       "all",
       ...new Set(noticeItem.map((item) => item.category)),
     ];
     useEffect(() => {
         dispatch(setCategory(allCategory));
     }, [dispatch, allCategory.length]);
    return (
      <>
        <div className="my-3 pt-3">
          <Title title={"Notice Board"} />
        </div>
        <div className="notice-box my-5">
          <div className="notice-navbar">
            <div className="d-flex justify-content-around align-item-center">
              <ul className="notice-nav">
                {noticeNav.map((navmenu, i) => {
                  return (
                    <li
                      onClick={() => {
                        dispatch(fitlerNotice(navmenu));
                      }}
                      key={i}
                      className="notice-nav-link"
                    >
                      {navmenu}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="notice-board">
            <NoticeContent />
          </div>
        </div>
      </>
    );
}

export default NoticeBox
