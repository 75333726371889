import { combineReducers, configureStore } from "@reduxjs/toolkit";
import NavbarSlice from "./Rducers/NavbarSlice";
import ReviewSlice from "./Rducers/ReviewSlice";
import RulesSlice from "./Rducers/RulesSlice";
import CourseSlice from './Rducers/CourseSlice';
import NoticeSlice from "./Rducers/NoticeSlice";
import AboutUsSlice from './Rducers/AboutUsSlice,';
import BookListSlice from "./Rducers/BookListSlice";
import resultSlice from './Rducers/ResultSlice';

const rootReducer = combineReducers({
    Rules : RulesSlice,
    Review: ReviewSlice,
    Navbar: NavbarSlice,
    Course: CourseSlice,
    Notice: NoticeSlice,
    result: resultSlice,
    AboutUs: AboutUsSlice,
    BookList: BookListSlice
})
export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({  
      serializableCheck: false,
    }),
});