import BTC from "../../assets/img/Aceivement/BTCertificate.jpg";
import C2016 from "../../assets/img/Aceivement/C2016.jpg";
import C2017 from "../../assets/img/Aceivement/C2017.jpg";
import C2018 from "../../assets/img/Aceivement/C2018.jpg";
import photo from "../../assets/img/Aceivement/img1.jpg";
import photo2 from "../../assets/img/Aceivement/img3.jpg";
import photo3 from "../../assets/img/Aceivement/img5.jpg";
import TTC from "../../assets/img/Aceivement/TtrainingC.jpg";
import { createSlice } from '@reduxjs/toolkit';
const initialState = [
  { id: 1, img: BTC, alt: "slide-1" },
  { id: 2, img: C2016, alt: "slide-2" },
  { id: 3, img: C2017, alt: "slide-3" },
  { id: 4, img: C2018, alt: "slide-4" },
  { id: 5, img: photo, alt: "slide-5" },
  { id: 6, img: photo2, alt: "slide-6" },
  { id: 7, img: photo3, alt: "slide-7" },
  { id: 8, img: TTC, alt: "slide-8" },
];

const AboutUsSlice = createSlice({
    name: 'about-us',
    initialState,
    reducers: {}
});

export default AboutUsSlice.reducer;
