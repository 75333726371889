import React from 'react'
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";


const GetInTouch = () => {
    return (
        <div className="col-md-3 ">
          <h4 className="footer-title py-3">GET IN TOUCH</h4>
          <div className="d-flex flex-column justify-content-start align-items-start">
            <div className="d-flex justify-content-between align-items-center text-light">
              <div className="pb-3">
                <FaPhoneAlt />
              </div>
              <div className="px-3">
                <p className="font-crimson ls-2">
                  01717464683 <br /> 01672888472
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center text-light">
              <div className="pb-3">
                <FaEnvelope />
              </div>
              <div className="px-3">
                <p className="font-crimson ls-2">anissiremas@gmail.com</p>
              </div>
            </div>
            <p className="text-light font-crimson ls-2">Hotline: 01717464683</p>
          </div>
        </div>
    );
}

export default GetInTouch
