import { createSlice } from "@reduxjs/toolkit";
import { BiBook } from "react-icons/bi";
import { FcApproval } from "react-icons/fc";

const initialState = {
  submenu: [
    {
      id: 1,
      label: "RESULT",
      icon: <FcApproval />,
      link: "/academic/result",
    },
    { id: 2, label: "BOOK-LIST", icon: <BiBook />, link: "/academic/book-list" },
  ],
  isClicked: false,
  isScrolled: false,
  location: {},
  isSubmenuOpen: false,
};

const NavbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    closeNavbar: (state) => {
      state.isClicked = !state.isClicked;
    },
    hideNavbar: (state) => {
      state.isClicked = false;
    },
    changeBgNavbar: (state) => {
      if (window.scrollY > 100) {
        state.isScrolled = true;
      } else {
        state.isScrolled = false;
      }
    },
    openSubmenu: (state, action) => {
      state.location = action.payload;
      state.isSubmenuOpen = true;
    },

    closeSubmenu: (state) => {
      state.isSubmenuOpen = false;
    },
  },
});

export const {
  closeNavbar,
  hideNavbar,
  changeBgNavbar,
  openSubmenu,
  closeSubmenu,
} = NavbarSlice.actions;

export default NavbarSlice.reducer;
