import React from "react";
import "./Notice.scss";
import HeaderContainer from "./../../ReuseableComp/HeaderContainer";
import NoticeBox from "./NoticeBox";
import Footer from './../../ReuseableComp/Footer/Footer';

const Notice = () => {
  document.title = "EMAS | Notice board";

  return (
    <div className="notice">
      <HeaderContainer title={"Notice"} />
      <div className="container">
        <NoticeBox />
      </div>
      <Footer />
    </div>
  );
};

export default Notice;
