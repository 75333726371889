import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import HeaderContainer from "./../../ReuseableComp/HeaderContainer";
import Title from "./../../ReuseableComp/Title";
import Footer from "./../../ReuseableComp/Footer/Footer";

const SingleNotice = () => {
  const { id } = useParams();
  const selector = useSelector((state) => state.Notice.data);
  const singleData = selector.filter((data) => data.id === parseInt(id));
  if(singleData.length === 0){
   return <Redirect to='/' />
  }else{
    const { img, title, notice } = singleData[0];
    return (
      <div>
        <HeaderContainer title="Notice Description" />
        <div className="container font-cormogrant">
          <div className="my-5">
            <Title title="Notice Info" />
          </div>
          <div className="d-flex flex-column justify-content-start align-items-start">
            <h3 className="text-title-sm text-bold text-uppercase my-5">
              {title}
            </h3>
            <div className="row gy-4 w-100">
              <div className="col-md-4">
                <img
                  className="notice-img"
                  src={img}
                  alt={`Notice-img-${id}`}
                />
              </div>
              <div className="col-md-8">
                <div className="d-flex flex-column justify-content-between">
                  <h4 className="notice-desctiption text-content">
                    Description:
                  </h4>
                  <p className="text-content">{notice}</p>
                </div>
              </div>
            </div>
            <div className="d-flex my-5">
              <p className="notice-download">Downloadable file : </p>
              <p className="text-content notice-link">Routine.pdf</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
};

export default SingleNotice;
