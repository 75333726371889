import React from 'react'

const Location = () => {
    return (
      <div className="text-light">
        <h4 className="footer-title py-3">OUR BRANCHES</h4>
        <div className="d-flex flex-column justify-content-center align-items-start font-cormogrant ls-2">
          <div>
            <h5 className="text-decoration-underline">WARI</h5>
            <p> House – 26/3A, Tipu Sultan Road, Wari, Dhaka – 1203.</p>
          </div>
          {/* <div className="d-flex">
            <div className="horizontal-bar"></div>
            <div className="horizontal-bar"></div>
          </div> */}
          <div>
            <h5 className="text-decoration-underline">MALIBAGH</h5>
            <p>
              Shahid Baki Road, Plot – 591, Block – C, Holding – 891 Malibagh
              Chowdhury Para, Khilgaon, Dhaka – 1219.
            </p>
          </div>
          <div className="d-flex mb-3">
            <div className="horizontal-bar"></div>
            <div className="horizontal-bar"></div>
          </div>
          <p>Sat – Thu , 3:00 pm – 9:00 pm </p>
        </div>
      </div>
    );
}

export default Location
