import Home from './container/Pages/Home/Home';
import './Scss/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import '@popperjs/core/dist/esm/popper'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Navbar from './container/ReuseableComp/Navbar/Navbar';
import Contact from './container/Pages/Contact/Contact';
import Gallery from './container/Pages/Gallery/Gallery';
import Notice from './container/Pages/Notice/Notice';
import AboutUs from './container/Pages/About_us/AboutUs';
import Error from './container/ReuseableComp/ErrorPage/Error';
import Academic from './container/Pages/Academic/Academic';
import Registration from './container/Pages/Registration/Registration';
import Course from './container/Pages/Course/Course';
import SingleNotice from './container/Pages/Notice/SingleNotice';
import Booklist from './container/Pages/Academic/BookList/Booklist';
import Result from './container/Pages/Academic/Result/Result';

function App() {
  return (
    <Router>
        <Navbar />
        <Switch>
          <Route exact path='/'  component={Home}/>
          <Route exact path='/contact'  component={Contact}/>
          <Route exact path='/course'  component={Course}/>
          <Route exact path='/gallery'  component={Gallery}/>
          <Route exact path='/notice'  component={Notice}/>
          <Route exact path='/notice/:id'  component={SingleNotice}/>
          <Route exact path='/about-us'  component={AboutUs}/>
          <Route exact path='/academic'  component={Academic}/>
          <Route exact path='/academic/book-list'  component={Booklist}/>
          <Route exact path='/academic/result'  component={Result}/>
          <Route exact path='/registration'  component={Registration}/>
          <Route   component={Error}/>
        </Switch>
    </Router>
  );
}

export default App;
