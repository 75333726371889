import React from 'react'
import HeaderContainer from '../../ReuseableComp/HeaderContainer'

const Gallery = () => {
    return (
        <section className='gallery font-cormogrant'>
          <HeaderContainer title='Gallery' />
          <h2 className='my-5 text-title text-center'>No Image Uploaded yet.</h2>
        </section>
    )
}

export default Gallery
