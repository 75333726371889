import React from "react";
import ReviewContent from "./ReviewContent";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Review = () => {
  const selector = useSelector((state) => state.Review);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    pauseOnHover: true,
    fade: true,
    lazyLoad: true
  };
  return (
    <section className="review font-cormogrant">
      <div className="container">
        <Slider {...settings}>
          {selector.map((review, index) => {
            const { img, comment, name } = review;
            return <ReviewContent key={index} img={img} comment={comment} name={name} index={index}/>;
          })}
        </Slider>
      </div>
    </section>
  );
};

export default Review;
