import React from 'react'
import HeaderContainer from '../../ReuseableComp/HeaderContainer'
import Title from '../../ReuseableComp/Title'
import './About-us.scss'
import SampleImage from '../../../assets/img/Teachers_photos/SampleImg.jpg'
import TeachersComment from './TeachersComment'
import TeachersPhoto from './TeachersPhoto'
import LazyLoad from 'react-lazyload';
import Slider2 from '../../../assets/img/Slider_Img/Slider2.jpg'
import Aceivement from './Aceivement'
import Footer from "./../../ReuseableComp/Footer/Footer";

const AboutUs = () => {
    return (
      <section className="about-us">
        <HeaderContainer title="About Us" />
        <div className="my-5">
          <Title title="Hear someting from us" />
        </div>
        <div className="row gx-0">
          <div className="col-md-8">
            <TeachersComment
              comment="  Hey there, Assalamualiku. This is Md. Anisur Rahman. I have been teaching mathematics for nearly a decade. Within these 12 years, I have discovered some tremendous techniques to teach my students. Most of my students always afraid of mathematics for no reason. They consider Mathematics as a very hard subject just because they are unable to explore this subject properly. If you understand Mathematics it will be fun for you. Why mathematics is a very important subject? Well, Most of the students targeted at foreign universities. Almost every foreign universities have a prerequisite of Mathematics for every subject. Also if you understand Mathematics easily every problem will be easy for you. So, students should pay more attention to Mathematics."
              name="Anis sir"
            />
          </div>
          <div className="col-md-4">
            <TeachersPhoto photo={SampleImage} />
          </div>
        </div>
        <div className="container">
          <div className="class-room">
            <div className="my-5">
              <Title title="Our class room" />
            </div>
            <p className="text-content font-cormogrant">
              We believe that the classroom is an important factor for studying. We ensure a hygienic and fascinating classroom for our students. As a consequence, our students complete their studies with a sound and clear mind.
            </p>
            <LazyLoad once>
              <img src={Slider2} className="img-fluid" alt="classroom" />
            </LazyLoad>
          </div>
          <Aceivement />
        </div>
        <Footer />
      </section>
    );
}

export default AboutUs
