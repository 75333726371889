export const results = [
  {
    sl: 1,
    student_name: "Radian Haque",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "May-19",
    passing_session: "May",
    passing_year: 2019,
  },
  {
    sl: 2,
    student_name: "Radian Haque",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "May-19",
    passing_session: "May",
    passing_year: 2019,
  },
  {
    sl: 3,
    student_name: "Radian Haque",
    subject: "MA",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A ",
    session: "May-19",
    passing_session: "May",
    passing_year: 2019,
  },
  {
    sl: 4,
    student_name: "Saad Hossain",
    subject: "MB",
    expected_grade_point: "pass",
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Jan-20",
    passing_session: "January",
    passing_year: 2020,
  },
  {
    sl: 5,
    student_name: "Farhin Sabrina",
    subject: "FPM",
    expected_grade_point: 8,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jan-20",
    passing_session: "January",
    passing_year: 2020,
  },
  {
    sl: 6,
    student_name: "Syed Minan",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-20",
    passing_session: "January",
    passing_year: 2020,
  },
  {
    sl: 7,
    student_name: "Syed Minan",
    subject: "MA",
    expected_grade_point: 7,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-20",
    passing_session: "January",
    passing_year: 2020,
  },
  {
    sl: 8,
    student_name: "Ashfaq Ahammad Jubair",
    subject: "MA",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-20",
    passing_session: "January",
    passing_year: 2020,
  },
  {
    sl: 9,
    student_name: "Syed Minan",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-20",
    passing_session: "January",
    passing_year: 2020,
  },
  {
    sl: 10,
    student_name: "Ashfaq Ahammad Jubair",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-20",
    passing_session: "January",
    passing_year: 2020,
  },
  {
    sl: 11,
    student_name: "Erfanul Haque",
    subject: "MB",
    expected_grade_point: "pass",
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Jan-20",
    passing_session: "January",
    passing_year: 2020,
  },
  {
    sl: 12,
    student_name: "Farhin Sabrina",
    subject: "MB",
    expected_grade_point: 8,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jan-20",
    passing_session: "January",
    passing_year: 2020,
  },
  {
    sl: 13,
    student_name: "Lamia Khan",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 14,
    student_name: "Shawda Zaman Prionty",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 15,
    student_name: "Afsana Ayen",
    subject: "FPM",
    expected_grade_point: 8,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 16,
    student_name: "Ashfaq Ahammad Jubair",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 17,
    student_name: "Munia Zaman",
    subject: "FPM",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 18,
    student_name: "Lamia Khan",
    subject: "MA",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 19,
    student_name: "Afsana Ayen",
    subject: "MA",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 20,
    student_name: "Tajrain Binte Hasan",
    subject: "MA",
    expected_grade_point: 8,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 21,
    student_name: "Miftahul Kadir Adib",
    subject: "MA",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 22,
    student_name: "Tazwara Tasneem ",
    subject: "MA",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 23,
    student_name: "Tahsin Hasan Ratul",
    subject: "MA",
    expected_grade_point: "Pass",
    obtained_grade_point: 4,
    obtained_grade: "C",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 24,
    student_name: "Nafiu Sikdar Nahid",
    subject: "MA",
    expected_grade_point: "Pass",
    obtained_grade_point: 4,
    obtained_grade: "C",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 25,
    student_name: "Alfi Islam",
    subject: "MA",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 26,
    student_name: "Lamia Khan",
    subject: "MB",
    expected_grade_point: 8,
    obtained_grade_point: 3,
    obtained_grade: "A*",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 27,
    student_name: "Shawda Zaman Prionty",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 28,
    student_name: "Afsana Ayen",
    subject: "MB",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 29,
    student_name: "Tajrain Binte Hasan",
    subject: "MB",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 30,
    student_name: "Miftahul Kadir Adib",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 31,
    student_name: "Munia Zaman",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 32,
    student_name: "Sadiba Samad",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 33,
    student_name: "Tazwara Tasneem ",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 34,
    student_name: "Tahsin Hasan Ratul",
    subject: "MB",
    expected_grade_point: "pass",
    obtained_grade_point: 5,
    obtained_grade: "C",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 35,
    student_name: "Nafiu Sikdar Nahid",
    subject: "MB",
    expected_grade_point: "pass",
    obtained_grade_point: 3,
    obtained_grade: "D",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 36,
    student_name: "Noorya Sikdar Tanha",
    subject: "MB",
    expected_grade_point: "pass",
    obtained_grade_point: 5,
    obtained_grade: "C",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 37,
    student_name: "Bushra Sikdar",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 38,
    student_name: "Alfi Islam",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 39,
    student_name: "Rowshny Ahmed",
    subject: "MB",
    expected_grade_point: "Pass",
    obtained_grade_point: 5,
    obtained_grade: "C",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 40,
    student_name: "Sayed Sajjad Kamran Haider",
    subject: "MB",
    expected_grade_point: "Pass",
    obtained_grade_point: 5,
    obtained_grade: "C",
    session: "Nov-20",
    passing_session: "November",
    passing_year: 2020,
  },
  {
    sl: 41,
    student_name: "Ahmed Bin K Zaman",
    subject: "MA",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-21",
    passing_session: "January",
    passing_year: 2021,
  },
  {
    sl: 42,
    student_name: "Issa Islam Nishanto",
    subject: "MA",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-21",
    passing_session: "January",
    passing_year: 2021,
  },
  {
    sl: 43,
    student_name: "Orundruti Khan Odrita",
    subject: "MA",
    expected_grade_point: 8,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jan-21",
    passing_session: "January",
    passing_year: 2021,
  },
  {
    sl: 44,
    student_name: "Umme Mariya Pinkey",
    subject: "MA",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-21",
    passing_session: "January",
    passing_year: 2021,
  },
  {
    sl: 45,
    student_name: "Ahmed Bin K Zaman",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-21",
    passing_session: "January",
    passing_year: 2021,
  },
  {
    sl: 46,
    student_name: "Issa Islam Nishanto",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-21",
    passing_session: "January",
    passing_year: 2021,
  },
  {
    sl: 47,
    student_name: "Alve Bepari Ahad",
    subject: "MB",
    expected_grade_point: "pass",
    obtained_grade_point: 5,
    obtained_grade: "C",
    session: "Jan-21",
    passing_session: "January",
    passing_year: 2021,
  },
  {
    sl: 48,
    student_name: "Orundruti Khan Odrita",
    subject: "MB",
    expected_grade_point: 8,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jan-21",
    passing_session: "January",
    passing_year: 2021,
  },
  {
    sl: 49,
    student_name: "Syed Faisal Hossain",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-21",
    passing_session: "January",
    passing_year: 2021,
  },
  {
    sl: 50,
    student_name: "Umme Mariya Pinkey",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-21",
    passing_session: "January",
    passing_year: 2021,
  },
  {
    sl: 51,
    student_name: "Anjum Afrin Khushi",
    subject: "MB",
    expected_grade_point: "pass",
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-21",
    passing_session: "January",
    passing_year: 2021,
  },
  {
    sl: 52,
    student_name: "Karima Jaman",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-21",
    passing_session: "January",
    passing_year: 2021,
  },
  {
    sl: 53,
    student_name: "Rownok Ahmed",
    subject: "FPM",
    expected_grade_point: 5,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 54,
    student_name: "Orundruti Khan Odrita",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 55,
    student_name: "Sayed Sajjad Kamran Haider",
    subject: "FPM",
    expected_grade_point: "Pass",
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 56,
    student_name: "Syed Faisal Hossain",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 57,
    student_name: "Fahad Ullah Mahir",
    subject: "FPM",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 58,
    student_name: "Ahmed Bin K Zaman",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 59,
    student_name: "Oshin Lamisa",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 60,
    student_name: "Issa Islam Nishanto",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 61,
    student_name: "Monjurul Islam ayon",
    subject: "FPM",
    expected_grade_point: "Pass",
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 62,
    student_name: "Rownok Ahmed",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 63,
    student_name: "Ryan Haque Rafi",
    subject: "MA",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 64,
    student_name: "Ryan Haque Rafi",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 65,
    student_name: "Fahad Ullah Mahir",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 66,
    student_name: "Oshin Lamisa",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 67,
    student_name: "Monjurul Islam ayon",
    subject: "MB",
    expected_grade_point: "Pass",
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 68,
    student_name: "Samia Rahman",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 69,
    student_name: "Samia Rahman",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "May-21",
    passing_session: " May",
    passing_year: 2021,
  },
  {
    sl: 70,
    student_name: "Kazi Rabit Jahir",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Nov-21",
    passing_session: "November",
    passing_year: 2021,
  },
  {
    sl: 71,
    student_name: "Sakif Ul Islam Iffat",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Nov-21",
    passing_session: "November",
    passing_year: 2021,
  },
  {
    sl: 72,
    student_name: "Akifa Islam Rad",
    subject: "MB",
    expected_grade_point: 5,
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Nov-21",
    passing_session: "November",
    passing_year: 2021,
  },
  {
    sl: 73,
    student_name: "Tajrian Binte Hasan",
    subject: "FPM",
    expected_grade_point: 6,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Nov-21",
    passing_session: "November",
    passing_year: 2021,
  },
  {
    sl: 74,
    student_name: "Abu Abdullah Anas Kamal",
    subject: "FPM",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 75,
    student_name: "Shahriar Abdullah ",
    subject: "FPM",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 76,
    student_name: "Tauhida Tanjum Sumaiya ",
    subject: "FPM",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 77,
    student_name: "Kazi Rabit Jahir",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 78,
    student_name: "Shadman Rahman ",
    subject: "FPM",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 79,
    student_name: "Akibur Rahman Rakin",
    subject: "FPM",
    expected_grade_point: "Pass",
    obtained_grade_point: 4,
    obtained_grade: "C",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 80,
    student_name: "Abu Abdullah Anas Kamal",
    subject: "MA",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 81,
    student_name: "Shahriar Abdullah ",
    subject: "MA",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 82,
    student_name: "Arshad Rahman",
    subject: "MA",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 83,
    student_name: "Jahidul Islam",
    subject: "MA",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 84,
    student_name: "Sheikh Shahriar Shadman",
    subject: "MA",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 85,
    student_name: "Fairoz Humaira ",
    subject: "MA",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 86,
    student_name: "Sanjida Hossain Phool",
    subject: "MA",
    expected_grade_point: 5,
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 87,
    student_name: "Ayman Parvej",
    subject: "MA",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 88,
    student_name: "Abu Abdullah Anas Kamal",
    subject: "MB",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 89,
    student_name: "Shahriar Abdullah ",
    subject: "MB",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 90,
    student_name: "Arshad Rahman",
    subject: "MB",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 91,
    student_name: "Jahidul Islam",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 92,
    student_name: "Tauhida Tanjum Sumaiya ",
    subject: "MB",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 93,
    student_name: "Sheikh Shahriar Shadman",
    subject: "MB",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 94,
    student_name: "Jasmin Zahid",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 95,
    student_name: "Fairoz Humaira ",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 96,
    student_name: "Shadman Rahman ",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 97,
    student_name: "Sanjida Hossain Phool",
    subject: "MB",
    expected_grade_point: "Pass",
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 98,
    student_name: "Shadman Sakib Talha ",
    subject: "MB",
    expected_grade_point: "Pass",
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 99,
    student_name: "Nafisa Tabassum orpa",
    subject: "MB",
    expected_grade_point: "Pass",
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 100,
    student_name: "Akibur Rahman Rakin",
    subject: "MB",
    expected_grade_point: "Pass",
    obtained_grade_point: 5,
    obtained_grade: "C",
    session: "Jan-22",
    passing_session: "January",
    passing_year: 2022,
  },
  {
    sl: 101,
    student_name: "Fahmida Muntaha",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 102,
    student_name: "Mohammed Naemur Rahman",
    subject: "MB",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 103,
    student_name: "Zayen Al Rasheed",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 104,
    student_name: "Farhan Ahmed",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 105,
    student_name: "Muhaimen Mortoza Mahadi",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 106,
    student_name: "Tahsen Shana Kabir ",
    subject: "MB",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 107,
    student_name: "Fardin Ahmed",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 108,
    student_name: "Tabassum Akter Safa",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 109,
    student_name: "Himadri Halim Himi",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 110,
    student_name: "Nagib Halim Nabbo",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 111,
    student_name: "Ayman Parvez",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 112,
    student_name: "Anushka Roy",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 113,
    student_name: "Mubashsharul Islam Labib",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 114,
    student_name: "Farhan Sahariya Shihab",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 115,
    student_name: "Joyeeta Ahmed",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 116,
    student_name: "Raisa Munia Alam",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 117,
    student_name: "Ahraf Al Basir Prantik",
    subject: "MB",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 118,
    student_name: "Rafiur Rahman Raqeen",
    subject: "MB",
    expected_grade_point: "Pass",
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 119,
    student_name: "Inaya Samara",
    subject: "FPM",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 120,
    student_name: "Rubama Hossain",
    subject: "FPM",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 121,
    student_name: "Ashshifa Zahan Tahna",
    subject: "FPM",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 122,
    student_name: "Rokeya Akter Safia",
    subject: "FPM",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 123,
    student_name: "Mahia Ahmed",
    subject: "FPM",
    expected_grade_point: 8,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 124,
    student_name: "Sheikh Shahriar Shadman",
    subject: "FPM",
    expected_grade_point: 8,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 125,
    student_name: "Jasmin Zahid",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 126,
    student_name: "Jahidul Islam",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 127,
    student_name: "Mohammed Naemur Rahman",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 8,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 128,
    student_name: "Arshad Rahman Abid",
    subject: "FPM",
    expected_grade_point: 7,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 129,
    student_name: "Zayeen Al Rashed",
    subject: "FPM",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 130,
    student_name: "Fardin Ahmed",
    subject: "FPM",
    expected_grade_point: 6,
    obtained_grade_point: 7,
    obtained_grade: "A",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 131,
    student_name: "Muhaimen Mortoza Mahadi",
    subject: "FPM",
    expected_grade_point: 6,
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 132,
    student_name: "Tahsen Shana Kabir ",
    subject: "FPM",
    expected_grade_point: 6,
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 133,
    student_name: "Joyeeta Ahmed",
    subject: "FPM",
    expected_grade_point: 6,
    obtained_grade_point: 6,
    obtained_grade: "B",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 134,
    student_name: "Sumaiya Khanom",
    subject: "FPM",
    expected_grade_point: "Pass",
    obtained_grade_point: 5,
    obtained_grade: "B",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 135,
    student_name: "Anushka Roy",
    subject: "FPM",
    expected_grade_point: "Pass",
    obtained_grade_point: 5,
    obtained_grade: "B",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 136,
    student_name: "Mubashsharul Islam Labib",
    subject: "FPM",
    expected_grade_point: "Pass",
    obtained_grade_point: 5,
    obtained_grade: "B",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 137,
    student_name: "Muhaimen Mortoza Mahadi",
    subject: "MA",
    expected_grade_point: 8,
    obtained_grade_point: 9,
    obtained_grade: "A*",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
  {
    sl: 138,
    student_name: "Akifa Islam Rad",
    subject: "MA",
    expected_grade_point: "Pass",
    obtained_grade_point: 5,
    obtained_grade: "B",
    session: "Jun-22",
    passing_session: "June",
    passing_year: 2022,
  },
];
