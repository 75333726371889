import React from 'react'
const TeachersComment = ({comment, name}) => {
    return (
      <div className="teachers-comment">
        <div className="vertical-bar mt-5 mb-3"></div>
        <p className="font-cormogrant text-content">
          {comment}
        </p>
        <div className="teachers-name text-sub-title">
          <div className="horizontal-bar mx-3"></div>
          <h4>{name}</h4>
        </div>
      </div>
    );
}

export default TeachersComment
