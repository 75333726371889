import FPMOL from "../../assets/img/Book-List/FPMOL.jpg";
import MAP1 from "../../assets/img/Book-List/Math-AP1.jpg";
import MAP2 from "../../assets/img/Book-List/Math-AP2.jpg";
import MB from "../../assets/img/Book-List/MB.jpg";
import P1 from "../../assets/img/Book-List/P1AL.jpg";
import P2 from "../../assets/img/Book-List/P2AL.jpg";
import P3 from "../../assets/img/Book-List/P3AL.jpg";
import P4 from "../../assets/img/Book-List/P4AL.jpg";
import M1 from "../../assets/img/Book-List/M1AL.jpg";
import S1 from "../../assets/img/Book-List/S1AL.jpg";
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [
    {
      id: 1,
      url: FPMOL,
      subject: "Futher Pure Mathematics",
      grade: "IGCSE (O'L)",
    },
    {
      id: 2,
      url: MAP1,
      subject: "Mathematics A (Paper 2)",
      grade: "IGCSE (O'L)",
    },
    {
      id: 3,
      url: MAP2,
      subject: "Mathematics A (Paper 2)",
      grade: "IGCSE (O'L)",
    },
    { id: 4, url: MB, subject: "Mathematics B", grade: "IGCSE (O'L)" },
    { id: 5, url: P1, subject: "Pure Mathematics 1 (P1)", grade: "IAL (A'L)" },
    { id: 6, url: P2, subject: "Pure Mathematics 2 (P2)", grade: "IAL (A'L)" },
    { id: 7, url: P3, subject: "Pure Mathematics 3 (P3)", grade: "IAL (A'L)" },
    { id: 8, url: P4, subject: "Pure Mathematics 4 (P4)", grade: "IAL (A'L)" },
    {
      id: 9,
      url: M1,
      subject: "Mechanics 1 (M1)",
      grade: "IAL (A'L)",
    },
    {
      id: 10,
      url: S1,
      subject: "Statistics 1 (S1)",
      grade: "IAL (A'L)",
    },
  ],
};

const BookListSlice = createSlice({
    name: 'BookList',
    initialState,
    reducers: {
        
    }
})

export default BookListSlice.reducer;
