import React from "react";
import { SiFacebook, SiInstagram } from "react-icons/si";
import { AiFillTwitterCircle } from "react-icons/ai";
import LazyLoad  from 'react-lazyload';

const AuthorCard = ({img, name, designation, phone , job}) => {
  return (
    <div className="col-md-6">
      <div className="card font-cormogrant">
        <LazyLoad>
            <img src={img} className="card-img-top" alt="Author-Img" />
        </LazyLoad>
        <div className="card-body">
          <div className="card-title">
            <h3>{name}</h3>
          </div>
          <div className="d-flex flex-column author-info">
            <h4>{designation}</h4>
            <p>B.SC (HONS), M.SC (MATHEMTICS)</p>
            <p>Jagannath University</p>
            <p>{job}</p>
            <p className="phone font-crimson">{phone}</p>
            <div className="icon-holder d-flex justify-content-center align-items-center">
                <a href="www.facebook.com" target="_blank">
                  <SiFacebook />
                </a>
                <a href="www.twitter.com" target="_blank">
                  <AiFillTwitterCircle />
                </a>
                <a href="www.instagram.com" target="_blank">
                  <SiInstagram />
                </a>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorCard;
