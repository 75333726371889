import React from 'react'
import HeaderContainer from './../../ReuseableComp/HeaderContainer';
import BookListContainer from './BookList/BookListContainer';

const Academic = () => {
    document.title="EMAS || Academic"
    return (
      <section className="academic">
        <HeaderContainer title="Academic" />
        <div className="book-list">
          <BookListContainer />
        </div>
      </section>
    );
}

export default Academic
