import React from 'react'
import './HeaderContainer.scss'
import Title from './Title';

const HeaderContainer = ({ title, subtitle }) => {
  return (
    <section className='header-container'>
      <div className="hero-background">
        <div className="d-flex flex-column">
          <div className="hero-title anim-left">
            <Title title={title} />
          </div>
          <p className="text-content hero-subtitle anim-right">{subtitle}</p>
        </div>
      </div>
    </section>
  );
};

export default HeaderContainer
