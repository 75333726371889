import React from "react";
import slider1 from "../../../assets/img/Slider_Img/Slider1.jpg"
import slider2 from "../../../assets/img/Slider_Img/Slider2.jpg";
import slider3 from '../../../assets/img/Slider_Img/Slider3.jpg'
import slider4 from '../../../assets/img/Slider_Img/Slider4.jpg'
import Title from "../../ReuseableComp/Title";
import LazyLoad from "react-lazyload";
import RegisterBtn from "../../ReuseableComp/RegisterBtn";
const CarouselSlider = () => {
  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="carousel-overlay">
            <LazyLoad>
              <img
                src={slider1}
                className="d-block carousel-img w-100 h-75"
                alt="First Slider"
              />
            </LazyLoad>
          </div>
          <div className="carousel-caption  font-cormogrant">
            <div className="carousel-firstSlide">
              <div className="carousel-bar"></div>
              <div className="first-slide-content">
                <h2 className="first-slide-title">EMAS</h2>
                <p className="font-cormogrant text-content ls-2">
                  An outstanding coaching center for Mathematics.
                </p>
                <RegisterBtn />
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="carousel-overlay">
            <img
              src={slider2}
              className="d-block carousel-img w-100 h-75"
              alt="Second Slider"
            />
          </div>
          <div className="carousel-caption  ">
            <Title key={2} title={"OUR CLASS ROOM"} />
          </div>
        </div>
        {/* second caroursel */}
        <div className="carousel-item">
          <div className="carousel-overlay">
            <img
              src={slider3}
              className="d-block carousel-img w-100 h-75"
              alt="Third Slider"
            />
          </div>
          <div className="carousel-caption  ">
            <div className="carousel-caption-text">
              <Title title={"Some of our student"} />
            </div>
          </div>
        </div>
        {/* third carousel */}
        <div className="carousel-item">
          <div className="carousel-overlay">
            <img
              src={slider4}
              className="d-block carousel-img w-100 h-75"
              alt="Fourth Slider"
            />
          </div>
          <div className="carousel-caption pt-5 ">
            <div className="carousel-caption-text">
              <Title title={"Some of our students"} />
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default CarouselSlider;
