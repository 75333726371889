import React from 'react'

const RegisterBtn = () => {
    return (
        <div
          className="btn btn-primary"
          onClick={() => {
            window.location.href = "https://form.jotform.me/201383224122441";
          }}
        >
          Register Here
        </div>
    );
}

export default RegisterBtn
