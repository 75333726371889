import { createSlice } from "@reduxjs/toolkit";
const initialState = [
  {id: 1, rule : "Over 12 years experienced mentor."},
  {id: 2,rule :"Edexcel Pearson trained advance and skilled teacher.",},
  {id: 3,rule :"Students can get individual help."},
  {id: 4,rule:"Extra time is given to weak students."},
  {id: 5, rule: "Providing chapter wise work sheet."},
  {id: 6, rule :"Solving yearly question paper in detail."},
  {id: 7, rule :"Weekly and monthly test for each subject (Mandatory)."},
  {id: 8, rule :"At least 20 sets mock exam."},
  {id: 9, rule :"Flashback classes over whole syllabus (before O’L & A’L)."},
  {id: 10, rule :"Students are not allowed after 5 minutes of starting the class."},
  {id: 11, rule :"Good communication with parents."},
  {id: 12, rule :"If any student is absent we inform the parents immediately."},
  {id: 13, rule :"Each batch has only 20 students for confirming the quality."},
  {id: 14, rule :"Exam marks are sent to parents through message."},
  {id: 15, rule :"Monthly parents meeting for students feedback."}
]
export const RuleSlice = createSlice({
    name: 'rules',
    initialState, 
    reducers: {}
})


export default RuleSlice.reducer;