import React from 'react'
import { FaMapMarkerAlt, FaMobileAlt, FaEnvelope } from "react-icons/fa";

const ContactInfo = ({location, address, campus, anim}) => {
    return (
      <div className={`contact-details ${anim}`}>
        <div className="details">
          <h3 className="details-title">{campus}</h3>
          <div className="icon-holder">
            <FaMapMarkerAlt />
          </div>
          <div className="details-text">
            <p className="text-title-sm">{location}</p>
            <strong>{address}</strong>
          </div>
          <div className="horizontal-bar"></div>
          <div className="icon-holder">
            <FaEnvelope />
          </div>
          <div className="details-text">
            <p className="text-title-sm">anissiremas@gmail.com</p>
            <strong>Write Us. This is the general email address.</strong>
          </div>
          <div className="horizontal-bar"></div>
          <div className="icon-holder">
            <FaMobileAlt />
          </div>
          <div className="details-text">
            <p className="text-title-sm">01717464683, 01672888472</p>
            <strong>Call Us. This is the general phone number.</strong>
          </div>
        </div>
      </div>
    );
}

export default ContactInfo
