import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const MobileSubmenu = ({changeHandler}) => {
    const submenus = useSelector(state=>state.Navbar.submenu);
    return (
        <div className=" mobile-submenu pr-4">
            {submenus.map(submenu=>{
                const {id, label, icon, link}  = submenu;
               return (
                 <Link key={id} to={link} className="mobile-submenu-link" onClick={changeHandler}>
                   {label}
                   <span className="mobile-submenu-icon">{icon}</span>
                 </Link>
               );
            })}
        </div>
    )
}

export default MobileSubmenu
