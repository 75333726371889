import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [
    {
      id: 1,
      grade: "igcse (o,l)",
      sub: [
        "Mathematics A",
        "Mathematics B",
        "Further Pure Mathematics",
        "M.D(Cambridge)",
        "Additional Mathematics (Cambridge)",
      ],
    },
    {
      id: 2,
      grade: "ial (a'l)",
      sub: [
        "Pure Mathematics (P1, P2, P3, P4)",
        "Statistics (S1, S2, S3, S4)",
        "Mechanics (M1, M2)",
      ],
    },
  ],
  fees: [
    {
      id: 1,
      text: "Admission fees per subject/unit 1000 tk",
    },
    {
      id: 2,
      text: "Per Subject/Unit (A' Level) 3500 tk",
    },
    {
      id: 3,
      text: "Per Subject/Unit (O' Level) 3000 tk",
    },
  ],
};

const CourseSlice = createSlice({
    name : 'course',
    initialState,
    reducers:{

    }
});

export default CourseSlice.reducer;
