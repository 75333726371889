import { createSlice } from "@reduxjs/toolkit";
import Review1 from "../../assets/img/Student_review/Review1.png";
import Review2 from "../../assets/img/Student_review/Review2.png";
import Review3 from "../../assets/img/Student_review/Review3.png";
const initialState = [
  {
    id: 1,
    img: Review1,
    name: "Radian Haque",
    comment:
      "My O'Level results wouldn't have been so great without EMAS. From having the best teachers to having the best environment for a student. Definitely one of my favorite coaching center.",
  },
  {
    id: 2,
    img: Review2,
    name: "Farin Sabrina Mahjabin",
    comment:
      "EMAS has been very helpful and has porvided me with a lot possibilities to improve in mathematics and gain greater Marks in the subject.",
  },
  {
    id: 3,
    img: Review3,
    name: "Syed Minan Ahsan",
    comment: "Very effective and well managed guidelines to prepare for my IGCSE Exam."
  },
];

const RevewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {},
});

export default RevewSlice.reducer;
