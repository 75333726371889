import React from "react";
import HeaderContainer from "./../../../ReuseableComp/HeaderContainer";
import { useSelector } from "react-redux";
import { useState } from "react";
import './Result.scss'
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";

const Result = () => {
  document.title = "EMAS || Result";
  const result = useSelector((state) => state.result.data);
  const [Rdata, setRdata] = useState(result);
  const [sword, setSword]  = useState("")
  
  const FilterHandler = e=>{
    const searchWord = e.target.value; 
    setSword(searchWord);
    const newResult = Rdata.filter(value=>{
      return (
        value.student_name.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.subject.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.session.toLowerCase().includes(searchWord.toLowerCase())
      );
    })
    if(searchWord === ""){
      setRdata(result)
    }else if (searchWord.length > 0) {
      setRdata(newResult);
    }
  }

  const clearInput = () => {
    setRdata(result) 
    setSword("")
  }

  return (
    <section className="academic">
      <HeaderContainer title="Result" />
      <div className="container py-3">
        <h1 className="text-center text-title result_heading">
          All Board results of EMAS (IGCSE)
        </h1>
        {/* search bar start  */}
        <div className="d-felx mx-auto result_search_box">
          <div className="searchInput d-flex py-4">
            <input
              type="text"
              placeholder="Name / Sub / Session"
              value={sword}
              onChange={FilterHandler}
            />
            <div className="searchIcon">
              {Rdata.length === result.length ? <FaSearch /> : <MdClose id="closeBtn" onClick={clearInput} />}
            </div> 
          </div>
        </div>
        {/* Search bar end  */}
        {Rdata.length === 0 ? (
          <div className="text-center my-5 error">No data found</div>
        ) : (
          <div className="table-responsive table_box">
            <table className="table table-hover px-5">
              <thead>
                <tr>
                  <th scope="col">S/L</th>
                  <th scope="col">Student Name</th>
                  <th scope="col">Subjects</th>
                  <th scope="col">Expected Grade-Point</th>
                  <th scope="col">Obtained Grade-Point</th>
                  <th scope="col">Obtain Grade</th>
                  <th scope="col">Session</th>
                </tr>
              </thead>
              <tbody>
                {Rdata.map((result, index) => {
                  const {
                    student_name,
                    subject,
                    expected_grade_point,
                    obtained_grade_point,
                    obtained_grade,
                    session,
                  } = result;

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{student_name}</td>
                      <td>{subject}</td>
                      <td>{expected_grade_point}</td>
                      <td>{obtained_grade_point}</td>
                      <td>{obtained_grade}</td>
                      <td>{session}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </section>
  );
};

export default Result;
