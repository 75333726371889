import React from 'react';
import { Link } from 'react-router-dom';

const QuickLink = () => {
    return (
      <div className="text-light">
        <h4 className="footer-title py-3">QUICK LINKS</h4>
        <div className="d-flex flex-column align-itmes-center jusity-content-center font-cormogrant ls-2 h-100">
          <Link className="quick-link-menu" to="/">
            Home
          </Link>
          <Link className="quick-link-menu" to="/course">
            course
          </Link>
          <Link className="quick-link-menu" to="/notice">
            Notice
          </Link>
          <Link className="quick-link-menu" to="/academic">
            Academic
          </Link>
          <Link className="quick-link-menu" to="/contact">
            Contact
          </Link>
          <Link className="quick-link-menu" to="/gallery">
            Gallery
          </Link>
          <Link className="quick-link-menu" to="/about-us">
            About us
          </Link>
        </div>
      </div>
    );
}

export default QuickLink
