import React from 'react'
import Title from './../../ReuseableComp/Title';
import Slider  from 'react-slick';
import { useSelector } from 'react-redux';

const Aceivement = () => {
    const settings = {
      className: "center",
      dots: true,
      centerMode: true,
      infinite: true,
      autoplay: true,
      centerPadding: "60px",
      slidesToScroll: 1,
      slidesToShow: 3,
      speed: 500,
      pauseOnHover: true,
      lazyLoad: true,
      autoplaySpeed: 4000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const AceivementData = useSelector(state=>state.AboutUs);
    return (
      <div className='aceivement-slider mb-5 pb-5'>
        <div className='my-5'>
          <Title title="Aceivement" />
        </div>
        <Slider {...settings}>
          {AceivementData.map((item) => {
            const { id, img, alt } = item;
            return (
              <div key={id} className='aceive-img-holder'>
                <img src={img} className="aceive-img" alt={alt} />
              </div>
            );
          })}
        </Slider>
      </div>
    );
}

export default Aceivement
