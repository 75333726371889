import React from 'react'
import { useState } from 'react';

const ConctactForm = () => {
        const initailState = {
          fname: "",
          lname: "",
          email: "",
          phone: "",
          msg: "",
        };

        const [formData, setFormData] = useState(initailState);
        const { fname, lname, email, phone, msg } = formData;
        const changeHandler = (e) => {
          setFormData({ [e.target.name]: e.target.value });
        };

        const submitHandler = (e) => {
          e.preventDefault();
          alert("Thank Your for submitting your query.");
          setFormData(initailState);
        };
    return (
      <div>
        <form className="contact-form" onSubmit={submitHandler}>
          <div className="row gy-4 my-3">
            <div className="col-md-6 d-flex flex-column">
              <label htmlFor="Firt Name">First Name (required)</label>
              <input
                className="contact-input"
                type="text"
                placeholder="First name"
                required
                onChange={changeHandler}
                value={fname}
                name="fname"
              />
            </div>
            <div className="col-md-6 d-flex flex-column">
              <label htmlFor="Last Name">Last Name (required)</label>
              <input
                className="contact-input"
                type="text"
                placeholder="First name"
                required
                onChange={changeHandler}
                value={lname}
                name="lanme"
              />
            </div>
            <div className="col-md-6 d-flex flex-column">
              <label htmlFor="Email">Email (required)</label>
              <input
                className="contact-input"
                type="email"
                placeholder="Email"
                required
                onChange={changeHandler}
                value={email}
                name="email"
              />
            </div>
            <div className="col-md-6 d-flex flex-column">
              <label htmlFor="Phone no">Phone no. (required)</label>
              <input
                className="contact-input"
                type="text"
                placeholder="Phone"
                required
                onChange={changeHandler}
                value={phone}
                name="phone"
              />
            </div>
            <div className="col-12 d-flex flex-column">
              <label htmlFor="Message">Your message</label>
              <textarea
                name="msg"
                className="contact-input"
                textarea="Enter your message"
                id=""
                rows="6"
                value={msg}
                onChange={changeHandler}
              />
            </div>
          </div>
          <button type="submit" className="contact-submit-btn">
            SUBMIT
          </button>
        </form>
      </div>
    );
}

export default ConctactForm
