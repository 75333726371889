import React from "react";
import AuthorCard from "./AuthorCard";
import Author1 from "../../../assets/img/Teachers_photos/Author1.png";
import Author2 from "../../../assets/img/Teachers_photos/Author2.png";
import Title from "../../ReuseableComp/Title";

const Author = () => {
  return (
    <section className="author-section">
      <div className="container py-5">
       <Title title={'Meet the author'} />
        <div className="row text-center my-4 gy-5">
          <AuthorCard 
            img={Author1}
            name="MD. ANISUR RAHMAN"
            designation="FOUNDER"
            phone="01717464683, 01672888472"
            job="SENIOR TEACHER ACADEMIA"
          />
          <AuthorCard
            img={Author2}
            name="Mrs. SHANTA MARIA"
            designation="CO-FOUNDER"
            phone="shantamaria@gmail.com"
            job="FORMAR TEACHER ACADEMIA"
          />
        </div>
      </div>
    </section>
  );
};

export default Author;
