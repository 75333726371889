import React from "react";
import CarouselSlider from "./CarouselSlider";
import Counter from "./Counter";
import RulesSection from "./RulesSection";
import './Home.scss'
import Course from './CourseSection';
import Author from "./Author";
import Review from "./Review";
import Footer from "../../ReuseableComp/Footer/Footer";
import NoticeBox from "../Notice/NoticeBox";

const Home = () => {
  document.title = "EMAS - An outstanding coaching center for Mathematics";
  return (
    <main>
      <CarouselSlider />
      <Counter />
      <RulesSection />
      <Course />
      <Author />
      <div className="container">
        <NoticeBox />
      </div>
      <Review />
      <Footer />
    </main>
  );
};

export default Home;
