import React from 'react'
import LazyLoad  from 'react-lazyload';

const TeachersPhoto = ({photo}) => {
    return (
      <LazyLoad>
        <img src={photo} className="img-fluid" alt="Sample-img" />
      </LazyLoad>
    );
}

export default TeachersPhoto
