import React from "react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import LazyLoad from "react-lazyload";

const ReviewContent = ({ index, img, name, comment }) => {
  return (
    <div key={index}>
      <div className="row">
        <div className="col-md-3 d-flex justify-content-center align-items-center">
          <LazyLoad>
            <img
              src={img}
              alt={`review-img-${index}`}
              className="testimonial-img"
            />
          </LazyLoad>
        </div>
        <div className="col-md-9 d-flex justify-content-start align-items-start mt-5 ">
          <div className="d-flex flex-column align-items-start justify-content-center">
            <div className="d-flex">
              <span className="testimonial-sign text-title">
                <FaQuoteLeft />
              </span>
              <p className="text-content">{comment}</p>
              <span className="testimonial-sign text-title">
                <FaQuoteRight />
              </span>
            </div>
            <div className="d-flex align-self-end justify-content-center align-items-center my-3">
              <div className="horizontal-bar mx-3 bg-btn"></div>
              <p className="text-btn testimonial-name text-title-sm">{name}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewContent;
