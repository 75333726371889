import { createSlice } from "@reduxjs/toolkit";
import { results } from "../../assets/Result/Result";

const initialState = {
  data: results,
};

const resultSlice = createSlice({
  name: "Board_result",
  initialState,
  reducers: {},
});

export default resultSlice.reducer;
