import React from "react";
import HeaderContainer from "./../../../ReuseableComp/HeaderContainer";
import "./BookList.scss";
import Footer from "./../../../ReuseableComp/Footer/Footer";
import BookListContainer from "./BookListContainer";

const Booklist = () => {
  document.title = "EMAS || Book-List";
  return (
    <section className="book-list">
      <HeaderContainer title="Book List" />
      <BookListContainer />
      <Footer />
    </section>
  );
};

export default Booklist;
