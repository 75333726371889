import React from 'react'
import { Link } from 'react-router-dom';

const Error = () => {
    return (
        <div className="container">
            <div className="d-flex flex-column align-items-center justify-content-center">
                <h2 className="display-2">Oops.... 404</h2>
                <h3  className="display-4 mb-4">The page you are finding is not available</h3>
                <Link to="/" className="btn btn-primary">Go Home</Link>
            </div>
        </div>
    )
}

export default Error
