import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../../assets/img/logo.svg";
import "./Navbar.scss";
import { useDispatch, useSelector } from "react-redux";
import { FaBars, FaLongArrowAltDown, FaTimes } from "react-icons/fa";
import {
  changeBgNavbar,
  closeNavbar,
  closeSubmenu,
  hideNavbar,
  openSubmenu,
} from "../../../Redux/Rducers/NavbarSlice";  
import Submenu from "./Submenu";
import MobileSubmenu from "./MobileSubmenu";
import RegisterBtn from "../RegisterBtn";

const Navbar = () => {
  const selector = useSelector((state) => state.Navbar);
  const { isClicked, isScrolled } = selector;
  const dispatch = useDispatch();

  const changeHandler = () => {
    dispatch(hideNavbar());
  };

  useEffect(() => {
    window.addEventListener('scroll', ()=>{
      dispatch(changeBgNavbar());
    })
  }, [dispatch,isScrolled]);

    const submenuHandler = (e) => {
      const text = e.target.text;
      console.log(e.screenX)
      if (text === "Academic") {
        let tempMenu = e.target.getBoundingClientRect();
        let center = (tempMenu.left + tempMenu.right) / 2 - tempMenu.width;
        //here added 20 because in menu section each nav-menu has a 20px of margin;
        let bottom = tempMenu.bottom + 10;
        dispatch(openSubmenu({ center, bottom }));
      }
    };
    

   window.addEventListener('click', (e)=>{
     if (!e.target.classList.contains("nav-menu")) {
       dispatch(closeSubmenu());
     }
   })
  

  return (
    <nav
      className={
        isScrolled
          ? "cnavbar fixed-top font-open navbar-active"
          : "cnavbar fixed-top font-open"
      }
    >
      <div className=" container d-flex justify-content-between align-items-center nav-content">
        <Link to="/" className="logo-holder">
          <img className="logo" src={logo} alt="logo" />
        </Link>
        <div
          className="mobile-menu-icon"
          onClick={() => dispatch(closeNavbar())}
        >
          {isClicked ? <FaTimes /> : <FaBars />}
        </div>
        <Submenu />
        <div className="flex-end menu-holder">
          <ul
            className={isClicked ? "show-menu menu-active" : "menu show-menu"}
          >
            <li className="nav-list">
              <NavLink
                exact={true}
                activeClassName="nav-menu-active"
                className="nav-menu ls-2 text-uppercase"
                to="/"
                onClick={changeHandler}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-list">
              <NavLink
                exact={true}
                activeClassName="nav-menu-active"
                className="nav-menu ls-2 text-uppercase"
                to="/course"
                onClick={changeHandler}
              >
                Course
              </NavLink>
            </li>
            <li className="nav-list">
              <NavLink
                exact={true}
                activeClassName="nav-menu-active"
                className="nav-menu ls-2 text-uppercase"
                to="/notice"
                onClick={changeHandler}
              >
                Notice
              </NavLink>
            </li>
            <li className="nav-list">
              <NavLink
                exact={true}
                activeClassName="nav-menu-active"
                className="nav-menu ls-2 text-uppercase"
                to="/academic"
                onClick={changeHandler}
                onMouseOver={submenuHandler}
              >
                Academic
                <span>
                  <FaLongArrowAltDown />
                </span>
              </NavLink>
              <MobileSubmenu changeHandler={changeHandler} />
            </li>
            <li className="nav-list">
              <NavLink
                exact={true}
                activeClassName="nav-menu-active"
                className="nav-menu ls-2 text-uppercase"
                to="/contact"
                onClick={changeHandler}
              >
                Contact
              </NavLink>
            </li>
            <li className="nav-list">
              <NavLink
                exact={true}
                activeClassName="nav-menu-active"
                className="nav-menu ls-2 text-uppercase"
                to="/gallery"
                onClick={changeHandler}
              >
                Gallery
              </NavLink>
            </li>
            <li className="nav-list">
              <NavLink
                exact={true}
                activeClassName="nav-menu-active"
                className="nav-menu ls-2 text-uppercase"
                to="/about-us"
                onClick={changeHandler}
              >
                About us
              </NavLink>
            </li>
            <li className="nav-list">
              <RegisterBtn />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
