import React from 'react'

const Title = ({title}) => {
    return (
      <div>
        <h2 className="text-center text-title font-cormogrant text-uppercase">
          {title}
        </h2>
      </div>
    );
}

export default Title
