import React from "react";
import { useState } from "react";
import LazyLoad from "react-lazyload";

const List = ({ id, url, subject, grade }) => {
  const [isOver, setIsOver] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const overlayHandler = () => {
    setIsOver(true);
  };

  return (
    <div className="col-md-3 my-5">
      <div
        className={
          isOver ? "book-img-container overlay-active" : "book-img-container"
        }
        onMouseOver={overlayHandler}
        onMouseOut={() => setIsOver(false)}
      >
        <LazyLoad key={id}>
          <img
            src={url}
            className="book-img text-title-s"
            alt={`BookList-img${id}`}
          />
          <div
            className={
              isOver
                ? "inner-content text-content inner-active"
                : "inner-content text-content"
            }
          >
            <h3>{subject}</h3>
            <p>{grade}</p>
            {/* <button
                onClick={() => setIsModalOpen(true)}
                className="btn btn-custom"
              >
                Details
              </button> */}
          </div>
        </LazyLoad>
      </div>

      {/* Modal

      <div
        className={isModalOpen ? "modal fade show" : "modal fade"}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
      >
        <div className="modal-dialog  modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                onClick={() => setIsModalOpen(false)}
              ></button>
            </div>
            <div className="modal-body">...</div>
          </div>
        </div>
      </div>
   */}
    </div>
  );
};

export default List;
