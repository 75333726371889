import React from 'react'
import CopyWrite from './CopyWrite';
import './Footer.scss';
import GetInTouch from './GetInTouch';
import Location from './Location';
import QuickLink from './QuickLink';
import SocialMediaIcon from './SocialMediaIcon';

const Footer = () => {
    return (
      <section className="footer bg-darkblue">
        <SocialMediaIcon />
        <div className="container">
          <div className="row gy-5">
            <GetInTouch />
            <div className="col-md-6">
              <Location />
            </div>
            <div className="col-md-3">
              <QuickLink />
            </div>
          </div>
          {/* Copywrite section */}
        </div>
        <CopyWrite />
      </section>
    );
}

export default Footer
