import React from 'react'
import {CountUp} from 'use-count-up';
import {FaLongArrowAltUp} from 'react-icons/fa';
import Title from '../../ReuseableComp/Title';
const Counter = () => {
    return (
      <div className="container my-5">
        <div className="row gy-5 gy-md-0">
          <div className="col-md-6 text-center">
            <Title title={"Total Students"} />
            <div className="d-flex align-items-center justify-content-center">
              <h3 className="display-3 font-crimson">
                <CountUp isCounting end={212} duration={3.2} />
              </h3>
              <h5 className="pt-2 conter-icon">
                <FaLongArrowAltUp />
              </h5>
            </div>
          </div>
          <div className="col-md-6 text-center">
            <Title title={'Teachers'} />
            <div className="d-flex align-items-center justify-content-center">
              <h3 className="display-3 font-crimson">
                <CountUp isCounting end={2} duration={2} />
              </h3>
              <h5 className="pt-2 conter-icon">
                <FaLongArrowAltUp />
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Counter
