import React from 'react'

const CourseSubTitle = ({subTitle}) => {
    return (
      <section className="anim-opacity">
        <h3 className="text-sub-title">{subTitle}</h3>
        <div className="d-flex">
          <div className="horizontal-bar"></div>
          <div className="horizontal-bar"></div>
          <div className="horizontal-bar"></div>
        </div>
      </section>
    );
}

export default CourseSubTitle
