import React from 'react'

const ContactLocation = () => {
  return (
    <div className="row font-cormogrant my-5">
      <div className="col-md-6">
        <p className="text-content text-bold text-uppercase text-center">
          Wari Brunch
        </p>
        <div className="text-center">
          <iframe
            title="googleMap1"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d861.9684553071746!2d90.4174989398582!3d23.715442763140285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9c0b954a457%3A0xf3e3f534f4eed826!2sStudy%20Point%20Academy!5e0!3m2!1sen!2sbd!4v1625049576838!5m2!1sen!2sbd"
            width="500"
            height="300"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div className="col-md-6">
        <p className="text-content text-bold text-uppercase text-center">
          Wari Campus
        </p>
        <div className="text-center">
          <iframe
           title='googleMap2'
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5217.657095090211!2d90.41647799668304!3d23.75112707270121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b87ca421b729%3A0xfd3bbb625519b38f!2zMjPCsDQ1JzA2LjYiTiA5MMKwMjUnMDAuOSJF!5e0!3m2!1sen!2sbd!4v1625052137920!5m2!1sen!2sbd"
            width="500"
            height="300"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default ContactLocation
