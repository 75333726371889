import React from "react";
import HeaderContainer from "./../../ReuseableComp/HeaderContainer";
import ConctactForm from "./ConctactForm";
import "./Contact.scss";
import ContactInfo from "./ContactInfo";
import Title from './../../ReuseableComp/Title';
import ContactLocation from './ContactLocation';
import Footer from "../../ReuseableComp/Footer/Footer";

const Contact = () => {


  return (
    <section className="contact">
      <HeaderContainer
        title="Contact Us"
        subtitle="Thank you for visiting EMAS website. Please, submit your questions and comments as directed below."
      />
      <div className="container ">
        <div className="row font-cormogrant gy-4 gx-0">
          <div className="col-md-8">
            <div className="d-flex h-100 flex-column justify-content-center align-items-start">
              <div className="vertical-bar"></div>
              <h2 className="text-title contact-cname">
                Educatory of <br /> Mathematics <br /> By Anis Sir
              </h2>
              <div className="horizontal-bar"></div>
              <p className="text-content my-3">
                Is there something you can’t find an answer to or do you have
                something else you need help with? Let us know by filling out
                the form below!
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <ContactInfo
              location="House – 26/3A, Tipu Sultan Road"
              address="Wari, Dhaka-1203"
              campus="Wari campus"
              anim="anim-right"
            />
          </div>
          <div className="row gx-0">
            <div className="col-md-4">
              <ContactInfo
                location="Shahid Baki Road, Plot – 591, Block – C, Holding – 891"
                address="Malibagh Chowdhury Para, Khilgaon, Dhaka – 1219."
                campus="Malibagh campus"
                anim="anim-left"
              />
            </div>
            <div className="col-md-8 px-5">
              <div className="contact-form-title">
                <div className="vertical-bar"></div>

                <h2 className="text-title">Got a question ?</h2>
                <p className="text-content">
                  Is there something you can’t find an answer to or do you have
                  something else you need help with? Let us know by filling out
                  the form below.
                </p>
                <div className="horizontal-bar"></div>
              </div>
              <ConctactForm />
            </div>
          </div>
        </div>
        <div className="my-5">
          <Title title="Location" />
        </div>
        <ContactLocation />
      </div>
      <Footer />
    </section>
  );
};

export default Contact;
