import React from 'react'

const Registration = () => {
    return (
        <div>
            <h2>Hey this is registration page.</h2>
        </div>
    )
}

export default Registration
