import React from "react";
import Rules from "./Rules";
import { useSelector } from "react-redux";
import RuleImage from "../../../assets/img/Teachers_photos/SampleImg.jpg";
import  LazyLoad  from 'react-lazyload';

const RulesSection = () => {
  const selector = useSelector((state) => state.Rules);
  return (
    <section className="bg-darkblue text-light">
      <div className="row gx-0">
        <div className="col-md-7 font-cormogrant order-2 order-md-0">
          <div className="rule-conten anim-left">
            <div className="vertical-bar my-3"></div>
            <h2 className="font-cormogrant text-title">
              Why do you choose EMAS ?
            </h2>
            <div className="horizontal-bar my-3"></div>
            <div className="list-rules">
              {selector.map((rule, index) => {
                return (
                  <div key={index}>
                    <Rules rule={rule} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-md-5 order-1 order-md-0">
          <LazyLoad >
            <img src={RuleImage} className="img-fluid" alt="RuleImg" />
          </LazyLoad>
        </div>
      </div>
    </section>
  );
};

export default RulesSection;
