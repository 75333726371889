import React from 'react'

const CopyWrite = () => {
    const Cyear = new Date().getFullYear();
    return (
      <div className="copy-rite d-flex justify-content-center align-items-center font-crimson bg-darkblue-2 pt-3 text-light">
        <p className="text-center">
          {`All right reserved || 2018-${Cyear} EMAS`}
          <br />
          Created by AshikDEV
         </p>
      </div>
    );
}

export default CopyWrite
