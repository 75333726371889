import React from "react";

const Rules = ({ rule }) => {
  return (
    <>
      <li className="text-content list-unstyled py-2">{rule.rule}</li>
    </>
  );
};

export default Rules;
