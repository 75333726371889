
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [
    // {
    //   id: 1,
    //   category: "Result",
    //   title: "Title 1",
    //   img: logo,
    //   notice: " Lorem Ipsum test sit amet isopia lorem ipsum sit amet  ",
    // },
    // {
    //   id: 2,
    //   title: "Title 2",
    //   img: logo,
    //   category: "Notice",
    //   notice: " Lorem Ipsum test sit amet isopia lorem ipsum sit amet  ",
    // },
    // {
    //   id: 3,
    //   title: "Title 3",
    //   img: logo,
    //   category: "Events",
    //   notice: " Lorem Ipsum test sit amet isopia lorem ipsum sit amet  ",
    // },
    // {
    //   id: 4,
    //   title: "Title 4",
    //   img: logo,
    //   category: "Notice",
    //   notice: " Lorem Ipsum test sit amet isopia lorem ipsum sit amet  ",
    // },
    // {
    //   id: 5,
    //   title: "Title 5",
    //   img: logo,
    //   category: "Events",
    //   notice: " Lorem Ipsum test sit amet isopia lorem ipsum sit amet  ",
    // },
  ],
  notices: [],
  singleNotice: [],
  isLoding: false,
  isActive: false,
  noticeNav: [],
};

const NoticeSlice = createSlice({
    name: 'notice',
    initialState,
    reducers: {
      setCategory : (state, {action, payload})=>{
       if(payload.length > 1){
         state.noticeNav = payload;
       }else{
         state.noticeNav = [];
       }
       
       state.notices = state.data
      },
      fitlerNotice : (state,action)=>{
        const category = action.payload;
        if(category === 'all'){
           state.notices = state.data;
           return;
        }
        const newNotices = state.data.filter((notice=> notice.category === category));
        if(newNotices.length > 1){
          state.notices = newNotices;
        }
      },
     getSingleNotice : (state, action)=>{
       const id = action.payload;
       const newNoticeContent = state.data.filter(notice=> notice.id === id);
      state.singleNotice = newNoticeContent;
     }
    }
})

export const {setCategory, fitlerNotice, getSingleNotice} = NoticeSlice.actions;

export default NoticeSlice.reducer;