import React from "react";
import "./Course.scss";
import HeaderContainer from "./../../ReuseableComp/HeaderContainer";
import Title from "./../../ReuseableComp/Title";
import CourseSubTitle from "./CourseSubTitle";
import { useSelector } from "react-redux";
import CourseSub from "./CourseSub";
import Footer from './../../ReuseableComp/Footer/Footer';

const Course = () => {
  document.title = "EMAS | Courses";
  const selector = useSelector((state) => state.Course);
  const CourseData = selector.data;
  const CourseFees = selector.fees;
  return (
    <div className="course font-cormogrant">
      <HeaderContainer title={"Course"} />
      <div className="container">
        <div className="my-4">
          <Title title={"Subjects"} />
        </div>
        {CourseData.map((courseItem) => {
          const { id, grade, sub } = courseItem;
          return (
            <div className="my-5 ls-2 anim-right" key={id}>
              <CourseSubTitle subTitle={grade} />
              {sub.map((subject, index) => {
                return <CourseSub key={index} subject={subject} />;
              })}
            </div>
          );
        })}
        <Title title={"Course Fees"} />
        <section className="my-5 ls-2">
          {CourseFees.map((feesItem) => {
            const { id, text } = feesItem;
            return (
              <div
                key={id}
                className="d-flex flex-column align-items-start justify-content-start my-3"
              >
                <p className="text-content">{text}</p>
              </div>
            );
          })}
          <p className="course-nb text-content">
            N.B: You are requested to pay your advance fees within first Week of
            each month.
          </p>
        </section>
        <section className="py-3" >
          <Title title={"Payment Option"} />
          <div className="d-flex flex-column justify-content-start text-content ls-2 py-3">
            <p>Cash</p>
            <p>
              Bkash - Personal
              <span className="font-crimson"> (01717464683)</span>
            </p>
            <p>
              Nagat - Personal
              <span className="font-crimson"> (01672888472)</span>
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Course;
